import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#F0140A';
const footerRelatedLinks = [
	{
		title: 'ECA Vaud',
		url: '/fr/projets/eca-vaud/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectFwtBrandingPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_001.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_001_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_002.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_003.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_004.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_005.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_006.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_008.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6_mobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_008_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_009.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_010.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_011.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_014.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_016.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_017.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12_mobile: file(
					relativePath: {
						eq: "projects/fwt/branding/fwt_branding_017_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_007.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/fwt-freeride-world-tour-branding/',
					lang: 'en',
				},
			]}
			title="Rebranding Freeride World Tour- Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Rebranding Freeride World Tour – FWT"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Traduire la quintessence de la liberté, l'adrénaline
							et la force viscérale du freeride dans la nouvelle
							identité.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Stratégie',
								'Workshops',
								'Brand design',
								'Poster',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Le Freeride World Tour est le championnat du monde
							de freeride gérant les compétitions
							professionnelles, de qualification, challenger et
							junior dans le monde. L’organisation nous a mandatés
							pour refondre l’identité visuelle de la compétition
							dans l’optique d’ouvrir un nouveau chapitre pour les
							années à venir, tout en proposant une architecture
							de marque qui soit logique entre les différentes
							catégories de compétition.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Par l’intermédiaire de workshops, nous avons
							clarifié la posture de la marque Freeride World Tour
							pour aujourd’hui comme pour demain. Sur ce
							fondement, une nouvelle identité visuelle a été
							proposée avec une approche permettant d’offrir plus
							de consistance et de force à la marque au fil des
							différentes saisons.
						</p>
						<p>
							Le travail comprend aussi une refonte du logo
							Freeride World Tour et la création de nouveaux logos
							pour répondre à l’évolution du nom de la
							compétition.
						</p>

						<hr />
						<h3>Pour approfondir</h3>
						<ButtonSecondary
							href="/fr/projets/fwt-freeride-world-tour/"
							text="Découvrir la refonte de site FWT"
							data-target
							className="-red"
						/>
					</div>
				</div>

				<div className="box text-content">
					<div className="list-two-columns">
						<div className="text-content">
							<h3 style={{ color: accent_color }}>
								ADN de la marque
							</h3>
							<p>
								L’ADN de marque est le cadre fondamental dans
								lequel la marque s’inscrit ; il est à la fois un
								cadre créatif et stratégique dans l’évolution de
								celle-ci. Il se définit par l’intermédiaire de
								workshops créatifs.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="ADN de la marque"
						/>
					</div>
				</div>

				<Image
					{...data.image_2.childImageSharp}
					alt="Logo"
					className="box col-full"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Nouveau nom, nouveau logo</h3>
						<p>
							L’ouverture de la saison 2024 rime avec un
							changement de nom ; le Freeride World Tour devient
							le FWT. Cette évolution vise à renforcer la
							cohérence et à améliorer la différenciation entre
							les catégories de compétition.
						</p>
						<p>
							Le logo FWT se veut une marque forte, puisant dans
							les traits distinctifs du logo Freeride World Tour
							tout en conservant une identité marquée.
						</p>
						<p>
							Une nouvelle gamme de codes couleur vient parfaire
							la distinction entre chaque catégorie de
							compétition.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_3.childImageSharp}
							alt="Couleurs"
						/>
						<Image
							{...data.image_4.childImageSharp}
							alt="Logos des compétitions"
							className="box"
						/>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Lifting et révision du logo Freeride World Tour</h3>
						<p>
							Le logo du Freeride World Tour, qui sera utilisé
							dans différents contextes, a été redessiné pour
							gagner en maturité et en lisibilité. L’harmonie de
							son lettrage et de ses formes graphiques a été
							méticuleusement redessinée.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_5.childImageSharp}
							alt="Modification du logo"
							className="box box--no-padding"
						/>
						<Video
							fluid={[
								{
									src: '11606f055360009dcec4dc6fba1038e0',
								},
								{
									src: 'ca138740514768729d035f40a42616d9',
									minWidth: 768,
								},
							]}
							alt="Freeride World Tour - Animation Logo"
							className="col-full box box--no-padding"
						/>
					</div>
				</Column>

				<Video
					fluid={[
						{
							src: '1fbbbb7de5e1e07af3b22e3f173c9653',
						},
						{
							src: 'aee16ecdf7b5e537a1961cc2103a7a0f',
							minWidth: 768,
						},
					]}
					className="col-full"
				/>

				<div className="box text-content">
					<h3>
						Respecter la montagne, sa grandeur et son caractère.
						<br />
						Oser l’attaquer en cherchant à parfaire la ligne ultime.
					</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								Le freeride symbolise la quintessence de la
								liberté, la perfection d’une ligne sur des
								montagnes vierges et immaculées.
							</p>
							<p>
								La nouvelle identité visuelle puise son
								inspiration de cette essence. Elle dévoile une
								structure épurée, marquée par des espaces vides
								et des images majestueuses de la montagne.
							</p>
						</div>
						<div className="text-content">
							<p>
								En opposition directe, l’identité se distingue
								par des assets graphiques évoquant l’audace,
								celle de défier l’immaculée afin d’y tracer la
								ligne suprême. L’adrénaline et la force
								viscérale du freeride se manifestent par une
								identité à la signature puissante, renforcée par
								des codes graphiques audacieux.
							</p>
						</div>
					</div>
				</div>

				<Image
					alt="Montagnes"
					className="col-full"
					fluid={[
						data.image_6_mobile.childImageSharp.fluid,
						{
							...data.image_6.childImageSharp.fluid,
							media: `(min-width: 768px)`,
						},
					]}
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_7.childImageSharp} alt="Ski" />
					<Image
						{...data.image_8.childImageSharp}
						alt="Site internet FWT sur mobile"
					/>
				</Gallery>

				<Image
					{...data.image_9.childImageSharp}
					className="col-full grid-spacing"
				/>

				<Video
					fluid={[
						{
							src: '60174ab339cff899f4a4e1855a943059',
						},
						{
							src: 'd1236d9debd6c949313eca8cc9ab786d',
							minWidth: 768,
						},
					]}
					className="col-full grid-spacing"
				/>

				<Video
					src="764cee63ab806865857f541b725f39ff"
					className="col-full grid-spacing"
				/>

				<Blockquote
					modifiers="big"
					uptitle="Témoignage client"
					quote="Une collaboration fluide et réactive tout au long du projet brand et web. Superhuit a su prendre en considération nos enjeux stratégiques et nos contraintes business pour y apporter des solutions fortes."
					more={
						<>
							<span className="text-content">
								Superhuit a été un véritable partenaire
								stratégique tout au long du projet, en partant
								du branding pour arriver à l’expérience web.
								Leurs réflexions et opinions sont restées
								objectives et centrées sur les objectifs que
								nous avions établis, avec un souci
								d’amélioration constant.
							</span>
							<span className="text-content">
								Que ce soit par des meetings, des workshops ou
								d’innombrables échanges d’e-mails et de calls,
								nous avons pu établir une vraie relation de
								confiance avec l’ensemble de l’équipe !
							</span>
						</>
					}
					cite="Gabriel Wicki – Head of communication"
				/>

				<Gallery className="col-full" modifiers="gutter">
					<Image {...data.image_10.childImageSharp} alt="Ski" />
					<Image
						{...data.image_11.childImageSharp}
						alt="Site internet FWT sur mobile"
					/>
				</Gallery>

				<Image
					alt="Posters"
					className="col-full grid-spacing box box--no-padding"
					fluid={[
						data.image_12_mobile.childImageSharp.fluid,
						{
							...data.image_12.childImageSharp.fluid,
							media: `(min-width: 768px)`,
						},
					]}
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Refonte du site internet du Freeride World Tour</h3>
						<p>
							L’organisation a fait appel à superhuit pour
							refondre son site internet afin de mieux distinguer
							les différentes compétitions et leurs données, tout
							en générant de la transversalité pour augmenter la
							notoriété des compétitions challenger et junior.
						</p>
						<ButtonSecondary
							href="/fr/projets/fwt-freeride-world-tour/"
							text="Découvrir la refonte de site FWT"
							data-target
							className="-red small-spacing"
						/>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_13.childImageSharp}
							alt="Site Internet FWT"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectFwtBrandingPage;
